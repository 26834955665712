<template>
  <div>
    <new-banner-sidebar />
    <!-- Filters -->
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-0">Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click.prevent="createBanner"
              >
                <feather-icon icon="PlusCircleIcon" class="mr-50" />
                <span class="text-nowrap">Novo banner</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        id="dashboard-banners-table"
        class="position-relative text-center"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados banners para esta busca"
        :busy="loading"
        v-sortable="sortableOptions"
        :primary-key="'id'"
      >
        <!-- Column: Ordem -->
        <template #cell(sequence)="data">
          <div class="text-wrap">
            <b-form-input
              class="text-center"
              @blur="setSequence(data.item.id, data.item.sequence)"
              v-model="data.item.sequence"
              placeholder="#"
              style="width: 55px"
            />
          </div>
        </template>
        <!-- Column: Data -->
        <template #cell(updated_at)="data">
          <div class="text-wrap">
            <div class="align-text-top">
              {{ data.item.updated_at | dateTime }}
            </div>
          </div>
        </template>
        <!-- Column: Título -->
        <template #cell(title)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.title }}</span>
          </div>
        </template>
        <!-- Column: Imagem desktop -->
        <template #cell(large_image_url)="data">
          <div class="text-wrap">
            <a :href="data.item.large_image_url" target="_blank"> 
              <img :src="data.item.large_image_url" width="300px"/>
            </a>
          </div>
        </template>
        <!-- Column: Imagem mobile -->
        <template #cell(small_image_url)="data">
          <div class="text-wrap">
            <a :href="data.item.small_image_url" target="_blank"> 
              <img :src="data.item.small_image_url" width="150px"/>
            </a>
          </div>
        </template>
        <!-- Column: Link -->
        <template #cell(link_url)="data">
          <div class="text-wrap">
            <a :href="data.item.link_url" target="_blank"> {{ data.item.link_url }} </a>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="editItem(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="deleteItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Remover</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import NewBannerSidebar from "../components/NewBannerSidebar";
import { sortable } from "@/libs/sortable";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    NewBannerSidebar
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  directives: {
    sortable,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      itemsPerPage: 1,
      tableColumns: [
        { key: "sequence", label: "Ordem" },
        { key: "updated_at", label: "Data" },
        { key: "title", label: "Título" },
        { key: "large_image_url", label: "Versão desktop" },
        { key: "small_image_url", label: "Versão mobile" },
        { key: "link_url", label: "Link" },
        { key: "actions", label: "Ações" },
      ]
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.DASHBOARD_BANNERS_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
    sortableOptions: function () {
      return {
        chosenClass: "is-selected",
        onEnd: this.dragDropChange
      };
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      searchDashBanners: types.SEARCH_DASHBOARD_BANNERS,
      deleteDashBanner: types.DELETE_DASHBOARD_BANNER,
      openNewBannerSidebar: types.OPEN_NEW_BANNER_SIDEBAR,
      updateDashBannerSequence: types.UPDATE_DASHBOARD_BANNER_SEQUENCE
    }),
    search(currentPage) {
      this.loading = true;
      this.searchDashBanners({
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os banners. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createBanner() {
      this.openNewBannerSidebar({
        saveAction: this.search 
      });
    },
    editItem(item) {
      this.openNewBannerSidebar({
        id: item.id,
        saveAction: this.search
      })
    },
    dragDropChange(event) {
      var id = event.item.attributes["data-pk"].value;
      const sequence = event.newIndex + 1 + (this.currentPage - 1) * 10;
      const banner = this.items[event.newIndex];
      if (banner && banner.sequence) {
        this.setSequence(id, banner.sequence);
      }
    },
    setSequence(id, sequence) {
      this.loading = true;
      this.updateDashBannerSequence({ id, sequence })
        .then((response) => {
          this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso!",
                text: "Ordem atualizada com sucesso.",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.search(this.currentPage);
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao atualizar a ordem. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      this.$swal({
        title: "Tem certeza?",
        text: `Você removerá o banner ${item.title}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deleteDashBanner(item.id)
            .then((response) => {
              this.search();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `O banner ${item.title} foi removida!`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao remover o banner ${item.title}. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#sys-version-table img {
  max-width: 75rem;
}
</style>
